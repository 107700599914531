<template>
    <comp-form title="健康指导分类" ref="comp_form" submit-api="/gateway/api/synursing/pc/cat/addCat" edit-api="/gateway/api/synursing/pc/cat/updateCat" id-key="catId" :submit-before="onSubmitBefore" @on-submit="onReset" height="auto">
        <template v-slot:form-box="data">
            <ElemFormItem title="分类名称" name="catName" required>
                <template v-slot:default="item">
                    <Input style="width:300px" v-model.trim="data.form.catName" :placeholder="item.cfg.ph"></Input>
                </template>
            </ElemFormItem>
            <ElemFormItem title="排序" name="sort" required>
                <template v-slot:default="item">
                    <Input style="width:300px" type="number" v-model.trim="data.form.sort" :placeholder="item.cfg.ph"></Input>
                </template>
            </ElemFormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../jointly/components/CompForm"
import ElemFormItem from "../../jointly/components/elem-form-item.vue"

export default {
    components: { CompForm, ElemFormItem },

    methods: {
        /**
         * 打开表单窗口
         */
        open(id, data) {
            this.$refs.comp_form.open(id, data)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.orgCode = parent.vue.loginInfo.userinfo.orgCode
            return data
        },

        onChangeIcon(e) {
            this.$refs.comp_form.setValue("icon", e.value.url)
        },
    },
}
</script>
